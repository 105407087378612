import { keyframes } from '@emotion/react'

import { styled } from 'utils/emotion'
import media from 'utils/mediaqueries'
import { toPx } from 'utils/strings'

import Icon from 'components/Icon'
import { colors } from 'components/Layout/variables'

const bounce = keyframes`
  0%, 100% {
    transform: translate3d(0,0,0);
  }

  60% {
    transform: translate3d(0, 15px, 0);
  }
`

export const ScrollIndicatorStyled = styled('button')({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  fontWeight: '700',
  letterSpacing: '0.05em',
  textDecoration: 'none',
  fontSize: '18px',
  textTransform: 'none',
  width: '100%',
  border: 0,
  backgroundColor: 'transparent',
  color: colors.green,
  cursor: 'pointer',
  transition: 'color .15s',

  '& > svg': {
    transition: 'transform .2s ease-in-out',
  },

  '&:hover, &:focus, &:active': {
    outline: 0,
    border: 0,
    color: colors.greenLight,

    // Actually referencing the styled component didn't work...
    '& > svg': {
      animation: `${bounce} 1s 3 ease-in-out`,
    },
  },

  [media('tablet')]: {
    width: 'auto',
  },
})

export const ScrollIndicatorIconStyled = styled(Icon)(
  {
    flex: 'none',
    height: 35,
  },
  (props) => ({
    margin: `${toPx(props.theme.spaceThirds)} auto`,
  })
)
