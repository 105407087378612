import React from "react";
import { graphql } from "gatsby";

import { styled } from "utils/emotion";
import media from "utils/mediaqueries";
import { parseAsText } from "utils/parser";

import ScrollIndicator from "components/ScrollIndicator";
import Headline from "components/Headline";
import Text from "components/Text";
import TextVisual from "components/TextVisual";
import Overlay from "components/Overlay";
import Grid from "components/Grid";
import GridItem from "components/GridItem";

const HeroSection = styled("section")({
  position: "relative",
  height: "100vh",
  display: "flex",
  width: "auto",
  alignItems: "flex-end",
  justifyContent: "center",
  zIndex: 1,

  [media("tabletFluid")]: {
    alignItems: "center",
    justifyContent: "center",
  },
});

const HeroBg = styled("div")(
  {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
  },
  (props) => ({
    backgroundImage: props.backgroundImage,
  })
);

const HeroHeadline = styled(Headline)(
  {
    position: "relative",
    wordBreak: "normal",
  },
  (props) => ({
    marginBottom: props.theme.space,
  })
);

const HeroText = styled(Text)({
  position: "relative",
  color: "white",
});

const HeroGridItem = styled(GridItem)({
  paddingTop: 50,
  alignSelf: "center",

  [media("tabletFluid")]: {
    paddingTop: 0,
  },
});

const HeroScrollIndicator = styled("div")({
  position: "absolute",
  bottom: 20,
  left: "50%",
  transform: "translateX(-50%)",
  display: "none",

  [media("laptop")]: {
    display: "block",
  },
});

const HeroScrollTarget = styled("div")({
  visibility: "hidden",
  position: "absolute",
  width: "100%",
  height: "0px",
});

/**
 * Hero component.
 */
export default function Hero({ pageBuilder }) {
  const {
    textvisual,
    headline,
    titleFont,
    text,
    backgroundImage,
    scrollIndicatorText,
  } = pageBuilder;

  const bgImage = backgroundImage[0]
    ? `url(' ${backgroundImage[0].url} ')`
    : "none";

  return (
    <>
      <HeroSection>
        <HeroBg backgroundImage={bgImage} />
        <Overlay />
        <Grid height="100%" center>
          {textvisual && (
            <TextVisual top="30%">{parseAsText(textvisual)}</TextVisual>
          )}
          <HeroGridItem mobile={1} tabletFluid={4 / 5}>
            {headline && (
              <HeroHeadline element="h1" uppercase titleFont={titleFont}>
                {parseAsText(headline)}
              </HeroHeadline>
            )}
            {text && <HeroText>{text}</HeroText>}
          </HeroGridItem>
        </Grid>
        <HeroScrollIndicator>
          <ScrollIndicator
            text={scrollIndicatorText}
            targetElement="#hero-scroll-target"
          />
        </HeroScrollIndicator>
      </HeroSection>
      <HeroScrollTarget id="hero-scroll-target" />
    </>
  );
}

export const query = graphql`
  fragment PageBuilderHeroQuery on Craft_PageBuilderHero {
    headline
    titleFont
    text
    textvisual
    backgroundImage {
      url
    }
    scrollIndicatorText: heroScrollIndicatorText
  }
`;
