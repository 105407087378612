import React from 'react';
import PropTypes from 'prop-types';
import scrollToElement from 'scroll-to-element';

import Text from 'components/Text';

import { ScrollIndicatorStyled, ScrollIndicatorIconStyled } from './styles';

const ScrollIndicator = ({ className, text, targetElement }) => {
  if (!targetElement) {
    return null;
  }

  return (
    <ScrollIndicatorStyled
      className={className}
      onClick={() => {
        scrollToElement(targetElement, {
          offset: 0,
          duration: 400,
        });
      }}
    >
      {text && <Text element="span">{text}</Text>}
      <ScrollIndicatorIconStyled
        className={className}
        type="arrowDown"
        themeName="green"
      />
    </ScrollIndicatorStyled>
  );
};

ScrollIndicator.propTypes = {
  targetElement: PropTypes.string.isRequired,
};

export default ScrollIndicator;
